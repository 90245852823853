// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

export const getData = createAsyncThunk(
	'appCompany/getData',
	async (params) => {
		const response = await axios.get(`${BASE_URL}Company`)
		return {
			params,
			data: response.data,
			totalPages: response.data.length,
		}
	}
)

export const addCompany = createAsyncThunk(
	'appCompany/addCompany',
	async (company, { dispatch }) => {
		const response = await axios.post(`${BASE_URL}Company`, company)
		await dispatch(getData())
		return response
	}
)

export const getCompany = createAsyncThunk(
	'appCompany/getCompany',
	async (id) => {
		const response = await axios.get(`${BASE_URL}Company/${id}`)
		return response.data
	}
)

export const updateCompany = createAsyncThunk(
	'appCompany/updateCompany',
	async (resource, { dispatch }) => {
		await axios.put(`${BASE_URL}Company`, resource)
		await dispatch(getAllData())
		return resource
	}
)

export const getCategoryOption = createAsyncThunk(
	'appResource/getCategoryOption',
	async () => {
		const response = await axios.get(`${BASE_URL}CategoryOption`)
		return response.data
	}
)

export const appCompanySlice = createSlice({
	name: 'appCompany',
	initialState: {
		data: [],
		total: 1,
		params: {},
		allData: [],
		selectedCompany: null,
		allCategoryOption: [],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getData.fulfilled, (state, action) => {
				state.data = action.payload.data
				state.params = action.payload.params
				state.total = action.payload.totalPages
			})
			.addCase(getCompany.fulfilled, (state, action) => {
				state.selectedCompany = action.payload
			})
			.addCase(getCategoryOption.fulfilled, (state, action) => {
				state.allCategoryOption = action.payload
			})
	},
})

export default appCompanySlice.reducer
