// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

export const getData = createAsyncThunk('appUserHoliday/getData', async id => {
  const response = await axios.get(`${BASE_URL}UserHoliday/GetUser?userId=${id}`)
  return {
    data: response.data
  }
}) 


export const appUseHolidaySlice = createSlice({
  name: 'appPublicHoliday',
  initialState: {
    data: [],
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
      })
  }
})

export default appUseHolidaySlice.reducer
