import axios from 'axios'
import { toast } from 'react-hot-toast'
import { ToastError } from '../../../utility/Toasts'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
	// ** jwtConfig <= Will be used by this service
	jwtConfig = { ...jwtDefaultConfig }

	// ** For Refreshing Token
	isAlreadyFetchingAccessToken = false

	// ** For Refreshing Token
	subscribers = []

	constructor(jwtOverrideConfig) {
		this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

		// ** Request Interceptor
		axios.interceptors.request.use(
			(config) => {
				// ** Get token from localStorage
				const accessToken = this.getToken()
				config.headers['Content-Type'] = 'application/json'

				// ** If token is present add it to request's Authorization Header
				if (accessToken) {
					// ** eslint-disable-next-line no-param-reassign
					config.headers.Authorization = `${
						this.jwtConfig.tokenType
					} ${accessToken.replace(/"/g, '')}`
				}

				return config
			},
			(error) => Promise.reject(error)
		)

		// ** Add request/response interceptor
		axios.interceptors.response.use(
			(response) => {
				// toast(t => (
				//   <ToastSuccess t={t} code={200} message={"Success - your request was successful"} title={"Request Succeeded"} />
				// ), { style: { backgroundColor: "#DBF5E7" } })

				return response
			},
			(error) => {
				// ** const { config, response: { status } } = error
				//const { config, response } = error
				const { response } = error
				//const originalRequest = config

				if (response && response.status !== 200) {
					toast(
						(t) => (
							<ToastError
								t={t}
								code={response.data.StatusCode}
								message={`${response.data.Messages.join(', ')}`}
								title={`Error Id: ${response.data.ErrorId}`}
							/>
						),
						{ style: { backgroundColor: '#F8CACA' } }
					)
				}

				if (response && response.status === 401) {
					// If we want to refresh the token do it in here
					return Promise.reject(error)
				}

				return Promise.reject(error)
			}
		)
	}

	onAccessTokenFetched(accessToken) {
		this.subscribers = this.subscribers.filter((callback) =>
			callback(accessToken)
		)
	}

	addSubscriber(callback) {
		this.subscribers.push(callback)
	}

	getToken() {
		return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
	}

	getRefreshToken() {
		return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
	}

	setToken(value) {
		localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
	}

	setRefreshToken(value) {
		localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
	}

	login(...args) {
		return axios.post(this.jwtConfig.loginEndpoint, ...args)
	}

	register(...args) {
		return axios.post(this.jwtConfig.registerEndpoint, ...args)
	}

	refreshToken() {
		return axios.post(this.jwtConfig.refreshEndpoint, {
			refreshToken: this.getRefreshToken(),
		})
	}
}
