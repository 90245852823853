// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports 
import axios from 'axios'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

export const getAllDatas = createAsyncThunk('appUsers/getAllDatas', async () => {
  const response = await axios.get(`${BASE_URL}AdminUser/user-detail-list`)
  return response.data
})

export const getUsersByCompany = createAsyncThunk('appResource/getUsersByCompany', async companyId => {
  const response = await axios.get(`${BASE_URL}AdminUser/user-company-list/${companyId}`)
  return response.data
})

export const getCandidate = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get(`${BASE_URL}AdminUser/${id}`)
  return response.data
})

export const getCompany = createAsyncThunk('appUsers/getCompany', async () => {
  const response = await axios.get(`${BASE_URL}Company`)
  return response.data
})

export const getPermission = createAsyncThunk('appProject/getPermission', async () => {
  const response = await axios.get(`${BASE_URL}Permission`)
  return response.data
}) 

export const updateCandidate = createAsyncThunk('appUsers/addCandidate', async (candidate, { dispatch }) => {
  await axios.put(`${BASE_URL}AdminUser`, candidate)
  await dispatch(getAllDatas())
  return candidate
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.get(`${BASE_URL}AdminUser/user-detail-list`)
  return {
    params,
    data: response.data,
    totalPages: response.data.length
  }
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post(`${BASE_URL}AdminUser`, user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllDatas())
  return user
})


export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    allCompanyUserData: [],
    selectedUser: null,
    allPermission: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDatas.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getUsersByCompany.fulfilled, (state, action) => {
        state.allCompanyUserData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      // .addCase(getUser.fulfilled, (state, action) => {
      //   state.selectedUser = action.payload
      // })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.allCompany = action.payload
      })
      .addCase(getPermission.fulfilled, (state, action) => {
        state.allPermission = action.payload
      })
      .addCase(getCandidate.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appUsersSlice.reducer
