// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

export const getAllDatas = createAsyncThunk(
	'appUsers/getAllDatas',
	async () => {
		const response = await axios.get(`${BASE_URL}User/user-detail-list`)
		return response.data
	}
)

export const getUsersByCompany = createAsyncThunk(
	'appResource/getUsersByCompany',
	async (companyId) => {
		const response = await axios.get(
			`${BASE_URL}User/user-company-list/${companyId}`
		)
		return response.data
	}
)

export const getCandidate = createAsyncThunk('appUsers/getUser', async (id) => {
	const response = await axios.get(`${BASE_URL}User/${id}`)
	return response.data
})
export const addCandidateSkill = createAsyncThunk(
	'appUsers/addCandidateSkill',
	async (skills, { dispatch }) => {
		await axios.post(`${BASE_URL}UserSkill/create-update-bulk`, skills)
		await dispatch(getAllDatas())
		return users
	}
)

export const removeCandidateSkill = createAsyncThunk(
	'appUsers/removeCandidateSkill',
	async (skills, { dispatch }) => {
		await axios.post(`${BASE_URL}UserSkill/delete`, skills)
		await dispatch(getCandidate(skills.userId))
		return users
	}
)

export const updateRatingScore = createAsyncThunk(
	'appUsers/updateCandidateSkillScore',
	async (skills, { dispatch }) => {
		await axios.put(`${BASE_URL}UserSkill/update-rating-score`, skills)
		await dispatch(getCandidate(skills.userId))
		return users
	}
)

export const getCompany = createAsyncThunk('appUsers/getCompany', async () => {
	const response = await axios.get(`${BASE_URL}Company`)
	return response.data
})

export const updateCandidate = createAsyncThunk(
	'appUsers/addCandidate',
	async (candidate, { dispatch }) => {
		await axios.put(`${BASE_URL}user`, candidate)
		await dispatch(getAllDatas())
		return candidate
	}
)
export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
	const response = await axios.get('/api/users/list/all-data')
	return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async (params) => {
	const response = await axios.get(`${BASE_URL}User/user-detail-list`)
	return {
		params,
		data: response.data,
		totalPages: response.data.length,
	}
})

export const getUser = createAsyncThunk('appUsers/getUser', async (id) => {
	const response = await axios.get('/api/users/user', { id })
	return response.data.user
})

export const addUser = createAsyncThunk(
	'appUsers/addUser',
	async (user, { dispatch, getState }) => {
		const response = await axios.post(`${BASE_URL}User`, user)
		await dispatch(getData(getState().users.params))
		await dispatch(getAllData())
		return response
	}
)

export const deleteUser = createAsyncThunk(
	'appUsers/deleteUser',
	async (user, { dispatch, getState }) => {
		await axios.post(`${BASE_URL}User/Delete`, user)
		await dispatch(getData(getState().users.params))
		await dispatch(getAllData())
		return id
	}
)

export const removeCandidateHoliday = createAsyncThunk(
	'appUserHoliday/removeCandidateHoliday',
	async (holiday, { dispatch }) => {
		await axios.post(`${BASE_URL}UserHoliday/delete`, holiday)
		await dispatch(getCandidate(holiday.userId))
		return users
	}
)

export const addHoliday = createAsyncThunk(
	'appUserHoliday/addUserHoliday',
	async (holiday, {}) => {
		await axios.post(`${BASE_URL}userholiday`, holiday)
		return holiday
	}
)

export const appUsersSlice = createSlice({
	name: 'appUsers',
	initialState: {
		data: [],
		total: 1,
		params: {},
		allData: [],
		allCompanyUserData: [],
		selectedUser: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllDatas.fulfilled, (state, action) => {
				state.allData = action.payload
			})
			.addCase(getUsersByCompany.fulfilled, (state, action) => {
				state.allCompanyUserData = action.payload
			})
			.addCase(getData.fulfilled, (state, action) => {
				state.data = action.payload.data
				state.params = action.payload.params
				state.total = action.payload.totalPages
			})
			// .addCase(getUser.fulfilled, (state, action) => {
			//   state.selectedUser = action.payload
			// })
			.addCase(getCompany.fulfilled, (state, action) => {
				state.allCompany = action.payload
			})
			.addCase(getCandidate.fulfilled, (state, action) => {
				state.selectedUser = action.payload
			})
	},
})

export default appUsersSlice.reducer
