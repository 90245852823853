// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

export const getAllData = createAsyncThunk(
	'appResource/getAllDatas',
	async () => {
		const response = await axios.get(
			`${BASE_URL}Opportunity/opportunity-detail-list/1`
		)
		return response.data
	}
)

export const getCompany = createAsyncThunk(
	'appResource/getCompany',
	async () => {
		const response = await axios.get(`${BASE_URL}Company`)
		return response.data
	}
)

export const getProject = createAsyncThunk(
	'appResource/getCompanyProject',
	async (companyId) => {
		const response = await axios.get(
			`${BASE_URL}Project/GetCompany/${companyId}`
		)
		return response.data
	}
)

export const getAvailabeSkills = createAsyncThunk(
	'appResource/getAvailabeSkills',
	async () => {
		const response = await axios.get(`${BASE_URL}Skill/get-resource-skill-list`)
		return response.data
	}
)

export const getData = createAsyncThunk(
	'appResource/getData',
	async (params) => {
		const response = await axios.get(
			`${BASE_URL}Opportunity/demand-list-resource/1` // changed to use same view as resource demand
		)
		return {
			params,
			data: response.data,
			totalPages: response.data.length,
		}
	}
)

export const getResource = createAsyncThunk(
	'appResource/getUser',
	async (id) => {
		const response = await axios.get(`${BASE_URL}Opportunity/${id}`)
		return response.data
	}
)

export const getOffer = createAsyncThunk('appResource/getOffer', async (id) => {
	const response = await axios.get(`${BASE_URL}Offer/demand-offer/${id}`)
	return response.data
})

export const addResource = createAsyncThunk(
	'appResource/addResource',
	async (resource, { dispatch }) => {
		const response = await axios.post(`${BASE_URL}Opportunity`, resource)
		//await dispatch(getData(getState().users.params))
		await dispatch(getAllData())
		return response
	}
)

export const addResourceSkill = createAsyncThunk(
	'appResource/addResourceSkill',
	async (skills, { dispatch }) => {
		await axios.post(`${BASE_URL}OpportunitySkill/create-update-bulk`, skills)
		await dispatch(getAllData())
		return resource
	}
)

export const removeResourceSkill = createAsyncThunk(
	'appUsers/removeResourceSkill',
	async (skills, { dispatch }) => {
		//console.log(skills)
		await axios.post(`${BASE_URL}OpportunitySkill/delete`, skills)
		await dispatch(getResource(skills.resourceId))
		return resource
	}
)

export const updateResource = createAsyncThunk(
	'appResource/addResource',
	async (resource, { dispatch }) => {
		await axios.put(`${BASE_URL}Opportunity`, resource)
		//await dispatch(getData(getState().users.params))
		await dispatch(getAllData())
		return resource
	}
)

export const deleteResource = createAsyncThunk(
	'appResource/deleteUser',
	async (id, { dispatch, getState }) => {
		//debugger
		await axios.delete(`${BASE_URL}Opportunity`, { id })
		await dispatch(getData(getState().users.params))
		await dispatch(getAllData())
		return id
	}
)

export const appResourceSlice = createSlice({
	name: 'appResource',
	initialState: {
		data: [],
		total: 1,
		params: {},
		allData: [],
		selectedUser: null,
		availableSkills: [],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllData.fulfilled, (state, action) => {
				state.allData = action.payload
			})
			.addCase(getData.fulfilled, (state, action) => {
				state.data = action.payload.data
				state.params = action.payload.params
				state.total = action.payload.totalPages
			})
			.addCase(getResource.fulfilled, (state, action) => {
				state.selectedResource = action.payload
			})
			.addCase(getOffer.fulfilled, (state, action) => {
				state.offers = action.payload
			})
			.addCase(getCompany.fulfilled, (state, action) => {
				state.allCompany = action.payload
			})
			.addCase(getProject.fulfilled, (state, action) => {
				state.allProject = action.payload
			})
			.addCase(getAvailabeSkills.fulfilled, (state, action) => {
				state.availableSkills = action.payload
			})
	},
})

export default appResourceSlice.reducer
