// ** Auth Endpoints
export default {
  loginEndpoint: `${process.env.REACT_APP_BASE_API_URL}Authenticate`,
  // registerEndpoint: 'http://localhost:5293/Authenticate/Register',
  // refreshEndpoint: 'http://localhost:5293/Authenticate/Refresh',
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
