// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

export const getData = createAsyncThunk('appPlanning/getData', async params => {
  const response = await axios.get(`${BASE_URL}Planning`)
  return {
    params,
    data: response.data,
    totalPages: response.data.length
  }
})

export const appPlanningSlice = createSlice({
  name: 'appPlanning',
  initialState: {
    data: [],
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appPlanningSlice.reducer
