// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

export const getData = createAsyncThunk(
	'appProject/getData',
	async (params) => {
		const response = await axios.get(`${BASE_URL}Project`)
		return {
			params,
			data: response.data,
			totalPages: response.data.length,
		}
	}
)

export const addProject = createAsyncThunk(
	'appProject/addProject',
	async (project, { dispatch }) => {
		const response = await axios.post(`${BASE_URL}Project`, project)
		await dispatch(getData())
		return response
	}
)

export const getProject = createAsyncThunk(
	'appProject/getProject',
	async (id) => {
		const response = await axios.get(`${BASE_URL}Project/${id}`)
		return response.data
	}
)

export const updateProject = createAsyncThunk(
	'appProject/updateProject',
	async (resource, { dispatch }) => {
		await axios.put(`${BASE_URL}Project`, resource)
		await dispatch(getAllData())
		return resource
	}
)

export const appProjectSlice = createSlice({
	name: 'appProject',
	initialState: {
		data: [],
		total: 1,
		params: {},
		allData: [],
		selectedProject: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getData.fulfilled, (state, action) => {
				state.data = action.payload.data
				state.params = action.payload.params
				state.total = action.payload.totalPages
			})
			.addCase(getProject.fulfilled, (state, action) => {
				state.selectedProject = action.payload
			})
	},
})

export default appProjectSlice.reducer
