import toast from 'react-hot-toast'
import Avatar from '@components/avatar'
import { Check, X } from 'react-feather'

export const ToastError = ({ t, code, title, message }) => {
	return (
		<div className='d-flex'>
			<div className='me-1'>
				<Avatar size='sm' color='danger' icon={<X size={12} />} />
			</div>
			<div className='d-flex flex-column text-danger'>
				<div className='d-flex justify-content-between'>
					<h6 className='text-danger'>{title}</h6>
					<X
						size={12}
						className='cursor-pointer'
						onClick={() => toast.dismiss(t.id)}
					/>
				</div>
				<span>
					Error {code}: {message}
				</span>
			</div>
		</div>
	)
}

export const ToastWarning = ({ t, code, title, message }) => {
	return (
		<div className='d-flex'>
			<div className='me-1'>
				<Avatar size='sm' color='warning' icon={<X size={12} />} />
			</div>
			<div className='d-flex flex-column text-warning'>
				<div className='d-flex justify-content-between'>
					<h6 className='text-warning'>{title}</h6>
					<X
						size={12}
						className='cursor-pointer'
						onClick={() => toast.dismiss(t.id)}
					/>
				</div>
				<span>
					Error {code}: {message}
				</span>
			</div>
		</div>
	)
}

export const ToastSuccess = ({ t, code, title, message }) => {
	return (
		<div className='d-flex'>
			<div className='me-1'>
				<Avatar size='sm' color='success' icon={<Check size={12} />} />
			</div>
			<div className='d-flex flex-column text-success'>
				<div className='d-flex justify-content-between'>
					<h6 className='text-success'>{title}</h6>
					<X
						size={12}
						className='cursor-pointer'
						onClick={() => toast.dismiss(t.id)}
					/>
				</div>
				<span>
					{code} - {message}
				</span>
			</div>
		</div>
	)
}
