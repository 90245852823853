// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

export const getData = createAsyncThunk(
	'appPublicHoliday/getData',
	async (params) => {
		const response = await axios.get(`${BASE_URL}PublicHoliday`)
		return {
			params,
			data: response.data,
			totalPages: response.data.length,
		}
	}
)

export const addPublicHoliday = createAsyncThunk(
	'appPublicHoliday/addPublicHoliday',
	async (publicholiday, { dispatch }) => {
		const response = await axios.post(`${BASE_URL}PublicHoliday`, publicholiday)
		await dispatch(getData())
		return response
	}
)

export const getPublicHoliday = createAsyncThunk(
	'appPublicHoliday/getPublicHoliday',
	async (id) => {
		const response = await axios.get(`${BASE_URL}PublicHoliday/${id}`)
		return response.data
	}
)

export const updatePublicHoliday = createAsyncThunk(
	'appPublicHoliday/updatePublicHoliday',
	async (resource, { dispatch }) => {
		await axios.put(`${BASE_URL}PublicHoliday`, resource)
		await dispatch(getAllData())
		return resource
	}
)

export const appPublicHolidaySlice = createSlice({
	name: 'appPublicHoliday',
	initialState: {
		data: [],
		total: 1,
		params: {},
		allData: [],
		selectedPublicHoliday: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getData.fulfilled, (state, action) => {
				state.data = action.payload.data
				state.params = action.payload.params
				state.total = action.payload.totalPages
			})
			.addCase(getPublicHoliday.fulfilled, (state, action) => {
				state.selectedPublicHoliday = action.payload
			})
	},
})

export default appPublicHolidaySlice.reducer
