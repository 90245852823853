// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import resources from '@src/views/apps/resource/store'
import resourceDemands from '@src/views/apps/resource-demand/store'
import projects from '@src/views/apps/project/store'
import companies from '@src/views/apps/company/store'
import skills from '@src/views/apps/skill/store'
import tags from '@src/views/apps/tag/store'
import candidates from '@src/views/apps/candidate/store'
import dashboards from '@src/views/dashboard/ecommerce/store'
import plannings from '@src/views/apps/planning/store'
import publicHolidays from '@src/views/apps/public-holiday/store'
import userHolidays from '@src/views/apps/candidate/addholiday/store'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  resources,
  resourceDemands,
  projects,
  companies,
  skills,
  tags,
  candidates,
  dashboards,
  plannings,
  publicHolidays,
  userHolidays
}

export default rootReducer
