// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

export const getAllData = createAsyncThunk('appResourceDemand/getAllDatas', async () => {
  const response = await axios.get(`${BASE_URL}Opportunity/demand-list-resource/1`)
  return response.data
})

export const getData = createAsyncThunk('appResourceDemand/getData', async params => {
  const response = await axios.get(`${BASE_URL}Opportunity/demand-list-resource/1`)
  return {
    params,
    data: response.data,
    totalPages: response.data.length 
  }
})

export const getResourceDemand = createAsyncThunk('appResource/getUser', async id => {
  const response = await axios.get(`${BASE_URL}Opportunity/demand-list-resource/1`)
  return response.data.filter(x => x.opportunityId === id)
})

export const assignCandidateToOpportunity = createAsyncThunk('appResourceDemand/addAssignment', async (data, { dispatch, getState }) => {
  await axios.post(`${BASE_URL}Offer`, data)
  await dispatch(getData(getState().data.params))
  await dispatch(getAllData())
  return user
})


export const appResourceDemandSlice = createSlice({
  name: 'appResourceDemand',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getResourceDemand.fulfilled, (state, action) => {
        state.selectedResourceDemand = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appResourceDemandSlice.reducer
