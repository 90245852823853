// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

export const getAllData = createAsyncThunk(
	'appSkillTag/getAllDatas',
	async () => {
		const response = await axios.get(`${BASE_URL}SkillTag`)
		return response.data
	}
)

export const getAllTags = createAsyncThunk(
	'appSkillTag/getAllTags',
	async () => {
		const response = await axios.get(`${BASE_URL}Tag`)
		return response.data
	}
)

export const getData = createAsyncThunk(
	'appSkillTag/getData',
	async (params) => {
		const response = await axios.get(`${BASE_URL}SkillTag`)
		return {
			params,
			data: response.data,
			totalPages: response.data.length,
		}
	}
)

export const getSkillTag = createAsyncThunk(
	'appSkillTag/getSkillTag',
	async (id) => {
		const response = await axios.get(`${BASE_URL}SkillTag/${id}`)
		return response.data
	}
)

export const updateSkillTag = createAsyncThunk(
	'appSkillTag/updateSkillTag',
	async (resource, { dispatch }) => {
		await axios.put(`${BASE_URL}Skill/UpdateWithTag`, resource)
		await dispatch(getAllData())
		return resource
	}
)

export const addSkillTag = createAsyncThunk(
	'appSkillTag/addSkillTag',
	async (resource, { dispatch }) => {
		const response = await axios.post(
			`${BASE_URL}Skill/CreateWithTag`,
			resource
		)
		await dispatch(getAllData())
		return response
	}
)

export const appSkillTagSlice = createSlice({
	name: 'appSkillTag',
	initialState: {
		data: [],
		total: 1,
		params: {},
		allData: [],
		tagsData: [],
		selectedSkillTag: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllData.fulfilled, (state, action) => {
				state.allData = action.payload
			})
			.addCase(getSkillTag.fulfilled, (state, action) => {
				state.selectedSkillTag = action.payload
			})
			.addCase(getData.fulfilled, (state, action) => {
				state.data = action.payload.data
				state.params = action.payload.params
				state.total = action.payload.totalPages
			})
			.addCase(getAllTags.fulfilled, (state, action) => {
				state.tagsData = action.payload
			})
	},
})

export default appSkillTagSlice.reducer
