// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

export const getAllData = createAsyncThunk('appTag/getAllDatas', async () => {
	const response = await axios.get(`${BASE_URL}Tag`)
	return response.data
})

export const getAllTags = createAsyncThunk('appTag/getAllTags', async () => {
	const response = await axios.get(`${BASE_URL}Tag`)
	return response.data
})

export const getAllTagGroups = createAsyncThunk(
	'appTag/getAllTagGroups',
	async () => {
		const response = await axios.get(`${BASE_URL}TagGroup`)
		return response.data
	}
)

export const getData = createAsyncThunk('appTag/getData', async (params) => {
	const response = await axios.get(`${BASE_URL}Tag`)
	//console.log(response)
	return {
		params,
		data: response.data,
		totalPages: response.data.length,
	}
})

export const getTag = createAsyncThunk('appTag/getTag', async (id) => {
	const response = await axios.get(`${BASE_URL}Tag/${id}`)
	return response.data
})

export const updateTag = createAsyncThunk(
	'appTag/updateTag',
	async (resource, { dispatch }) => {
		await axios.put(`${BASE_URL}Tag`, resource)
		await dispatch(getAllData())
		return resource
	}
)

export const addTag = createAsyncThunk(
	'appTag/addTag',
	async (resource, { dispatch }) => {
		const response = await axios.post(`${BASE_URL}Tag`, resource)
		await dispatch(getAllData())
		return response
	}
)

export const appTagSlice = createSlice({
	name: 'appTag',
	initialState: {
		data: [],
		total: 1,
		params: {},
		allData: [],
		tagGroupData: [],
		selectedTag: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllData.fulfilled, (state, action) => {
				state.allData = action.payload
			})
			.addCase(getAllTagGroups.fulfilled, (state, action) => {
				state.tagGroupData = action.payload
			})
			.addCase(getTag.fulfilled, (state, action) => {
				state.selectedTag = action.payload
			})
			.addCase(getData.fulfilled, (state, action) => {
				state.data = action.payload.data
				state.params = action.payload.params
				state.total = action.payload.totalPages
			})
	},
})

export default appTagSlice.reducer
