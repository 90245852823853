// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

export const getData = createAsyncThunk('appDashboard/getData', async params => {
  const response = await axios.get(`${BASE_URL}Dashboard`)
  return {
    params,
    data: response.data
    }
}) 

export const getStats = createAsyncThunk('appDashboard/getStats', async params => {
  const response = await axios.get(`${BASE_URL}Dashboard/GetStats`)
  return {
    params,
    stats: response.data
    }
})

export const appDashboardSlice = createSlice({
  name: 'appProject',
  initialState: {
    data: [],
    stats: {},
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
      })
      .addCase(getStats.fulfilled, (state, action) => {
        state.stats = action.payload.stats
        state.params = action.payload.params
      })
  }
})

export default appDashboardSlice.reducer
